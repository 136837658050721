import { Api } from "@technology/web3d/dist/esnext/Api.js";
import { Web3DPlugin } from "@technology/web3d/dist/esnext/Web3DPlugin.js";
import {ThreejsModel} from "./ThreejsModel.js";
import {
    ShaderMaterialParameters
} from "three";
import {Web3DMeshMaterial, Web3DMeshMaterialParameters} from "@technology/web3d/dist/esnext/Rendering/Web3DMeshMaterial.js";
import {
    Web3DPointsMaterial,
    Web3DPointsMaterialParameters
} from "@technology/web3d/dist/esnext/Rendering/Web3DPointsMaterial.js";
import {DepthPeelingMaterial} from "@technology/web3d/dist/esnext/Rendering/DepthPeelingMaterial.js";
import {Web3DLineMaterial} from "@technology/web3d/dist/esnext/Rendering/Web3DLineMaterial.js";
import {LineMaterialParameters} from "three/examples/jsm/lines/LineMaterial.js";
import {Web3DMeshPointsMaterial} from "@technology/web3d/dist/esnext/Rendering/Web3DMeshPointsMaterial.js";
import {MeshPoints} from "@technology/web3d/dist/esnext/CustomObjects/MeshPoints.js";
import {MeshPointsGeometry} from "@technology/web3d/dist/esnext/CustomObjects/MeshPointsGeometry.js";

export class ThreejsPlugin extends Web3DPlugin {
    private _api: Api;

    get name(): string {
        return "threejs";
    }

    set api(api: Api) {
        this._api = api;
    }

    createModel(): ThreejsModel {
        const model = new ThreejsModel(this._api);
        this._api.models.add(model);
        return model;
    }

    createMeshMaterial(parameters: Web3DMeshMaterialParameters): Web3DMeshMaterial {
        return Web3DMeshMaterial.createSettingsAwareMaterial(this._api.settingsDispatcher, this._api.renderingManager, parameters);
    }

    createLineMaterial(parameters: LineMaterialParameters): Web3DLineMaterial {
        parameters.clippingPlanes = this._api.renderingManager.clippingPlanes;
        return new Web3DLineMaterial(parameters, this._api.renderingManager.uniforms);
    }

    createMeshPointsMaterial(parameters: Web3DPointsMaterialParameters): Web3DMeshPointsMaterial {
        parameters.clippingPlanes = this._api.renderingManager.clippingPlanes;
        return new Web3DMeshPointsMaterial(parameters, this._api.renderingManager.uniforms);
    }

    createMeshPoints(geometry: MeshPointsGeometry, material: Web3DMeshPointsMaterial): MeshPoints {
        return new MeshPoints(geometry, material, this._api.camera, this._api.container);
    }

    /**
     * @deprecated Use createMeshPointsMaterial instead
     */
    createPointsMaterial(parameters: Web3DPointsMaterialParameters): Web3DPointsMaterial {
        parameters.clippingPlanes = this._api.renderingManager.clippingPlanes;
        return new Web3DPointsMaterial(parameters, this._api.renderingManager.uniforms);
    }

    createDepthPeelingMaterial(parameters: ShaderMaterialParameters): DepthPeelingMaterial {
        parameters.clippingPlanes = this._api.renderingManager.clippingPlanes;
        return new DepthPeelingMaterial(parameters, this._api.renderingManager.uniforms);
    }

    addAnimationFrameListener(listener: (timestamp: number) => void): void {
        this._api.renderingManager.addAnimationFrameListener(listener);
    }

    redraw(): void {
        this._api.renderingManager.redraw();
    }
}
