import {Box3, Frustum, Matrix4, Plane, Vector3} from "three";

export class FrustumHelper {
    static transformFrustum(frustum: Frustum, matrix: Matrix4, out: Frustum = new Frustum()): Frustum {
        for (let i = 0; i < out.planes.length; i++)
            out.planes[i].copy(frustum.planes[i]).applyMatrix4(matrix);
        return out;
    }

    static planesIntersectBoxInternal = (() => {
        const point = new Vector3();

        return (planes: Plane[], box: Box3, contain: boolean) => {
            for (let i = 0, length = planes.length; i < length; i++) {
                const normal = planes[i].normal;
                point.set(
                    (normal.x >= 0.0) !== contain ? box.max.x : box.min.x,
                    (normal.y >= 0.0) !== contain ? box.max.y : box.min.y,
                    (normal.z >= 0.0) !== contain ? box.max.z : box.min.z
                );
                if (planes[i].distanceToPoint(point) < 0)
                    return false;
            }
            return true;
        };
    })();

    static planesIntersectBox(planes: Plane[], box: Box3): boolean {
        return FrustumHelper.planesIntersectBoxInternal(planes, box, false);
    }

    static planesContainBox(planes: Plane[], box: Box3): boolean {
        return FrustumHelper.planesIntersectBoxInternal(planes, box, true);
    }

    public static insideNonIntersectingPlanes(point: Vector3, clipPlanes: Plane[]): boolean {
        let insideClipPlanes = true;
        for (const clipPlane of clipPlanes)
            insideClipPlanes = insideClipPlanes && clipPlane.distanceToPoint(point) >= 0;
        return insideClipPlanes;
    }
}
