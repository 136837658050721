import { ShaderMaterial, NormalBlending, NoBlending, ShaderMaterialParameters } from "three";
import { GlobalMaterialUniforms } from "./RenderingManager.js";

export class DepthPeelingMaterial extends ShaderMaterial {
    readonly isDepthPeelingMaterial = true;

    protected _globalUniforms: GlobalMaterialUniforms;

    origTransparent: boolean;
    private readonly origOpacity: number;

    // @ts-ignore
    get transparent(): boolean {
        if (this._globalUniforms && this._globalUniforms.globalTransparent !== undefined)
            return this._globalUniforms.globalTransparent;
        return this.origTransparent;
    }

    override set transparent(v: boolean) {
        this.origTransparent = v;
    }

    protected updateTransparency(): void {
        if (this._globalUniforms) {
            if (this._globalUniforms.globalOpacity) this.uniforms.opacity.value = this.origOpacity * this._globalUniforms.globalOpacity.value;
            const transparent = this.origTransparent || this._globalUniforms.globalTransparent !== undefined;
            this.uniforms.transparent.value = Number(transparent);
            this.blending = transparent ? NormalBlending : NoBlending;
        }
    }

    constructor(parameters: ShaderMaterialParameters, globalUniforms?: GlobalMaterialUniforms) {
        super(parameters);
        this._globalUniforms = globalUniforms;
        this.origOpacity = parameters.uniforms.opacity.value;
        this.origTransparent = parameters.transparent;
        // replace transparent property of parent class with get/set
        delete this.transparent;
    }
}
