import {GeometryObject3D, Model, SelectableModel, SnappedPickableModel} from "./Model.js";
import {Caster} from "./Picker/Caster.js";
import {IIntersection} from "./Picker/IIntersection.js";
import {SnapType} from "./common.js";
import {Box3, Intersection, Mesh, Object3D} from "three";
import {Api} from "./Api.js";
import {FrustumHelper} from "./Helpers/FrustumHelper.js";

export abstract class GenericModel extends Model implements SnappedPickableModel, SelectableModel {
    readonly isSelectable = true;
    protected selection: number[] = [];

    constructor(modelId: string, protected _api: Api) {
        super(modelId);
    }

    async pick(caster: Caster): Promise<IIntersection> {
        if (!caster.frustum.intersectsBox(this.getModelBoundingBox()))
            return;

        const intersections = this.intersectModel(caster);
        if (intersections.length === 0) return;

        for (const inn of intersections)
            if (inn.distanceToRay === undefined) inn.distanceToRay = caster.ray.distanceToPoint(inn.point);

        const inn = intersections.reduce((a, b) =>
            (a.distanceToRay*5 + a.distance) < (b.distanceToRay*5 + b.distance) ? a : b
        ) as IIntersection;
        return Promise.resolve(inn);
    }

    async pickSnapped(caster: Caster, snapTypes: SnapType[]): Promise<IIntersection[]> {
        if (!snapTypes.includes(SnapType.FACE) || !caster.ray.intersectsBox(this.getModelBoundingBox()))
            return;

        const intersections = this.intersectModel(caster);
        return Promise.resolve(intersections);
    }

    protected intersectModel(caster: Caster): Array<IIntersection & Intersection> {
        const intersections = caster.intersectObject(this.root, true) as Array<IIntersection & Intersection>;
        intersections.forEach(inn => {
            inn.snapType = SnapType.FACE;
            if (inn.face)
                inn.normal = inn.face.normal;
            inn.id = inn.object.id;
            inn.model = this;
            inn.caster = caster;
        });
        return intersections;
    }

    protected traverse(callback: (object: Object3D) => any): void {
        this.root.traverse(callback);
    }

    async areaPick(caster: Caster, containedOnly: boolean): Promise<IIntersection> {
        if (!FrustumHelper.planesIntersectBox(caster.frustum.planes, this.getModelBoundingBox())) {
            return null;
        }

        const objects: Object3D[] = [];
        this.traverse(o => {
            if ((o as GeometryObject3D).geometry) {
                const bb = new Box3();
                bb.setFromObject(o);
                if (containedOnly) {
                    if (FrustumHelper.planesContainBox(caster.frustum.planes, bb)) {
                        objects.push(o);
                    }
                } else {
                    if (FrustumHelper.planesIntersectBox(caster.frustum.planes, bb)) {
                        objects.push(o);
                    }
                }
            }
        });
        if (objects.length === 0) return;

        return {
            id: objects[0].id,
            childrenIds: objects.map(o => o.id),
            object: this.root,
            model: this,
            caster: caster
        };
    }

    _setSelection(ids: number[]): void {
        this._clearSelection();

        // if no ids provided, just make all geometries highlighted
        if (!ids) {
            ids = [];
            this.traverse((o: Object3D) => {
                if ((o as Mesh).geometry) ids.push(o.id);
            });
        }

        for (const id of ids) {
            const obj = this.root.getObjectById(id) as GeometryObject3D;
            obj.userData.id = obj.id;
            this._api.selectionEffectPass.addObject(this.modelId, obj.id, obj);
        }
        this.selection = ids;
        this._api.renderingManager.redraw();
    }

    _clearSelection(): void {
        for (const id of this.selection)
            this._api.selectionEffectPass.removeObject(this.modelId, id);
        this.selection.length = 0;
        this._api.renderingManager.redraw();
    }

    refreshBoundingBox(): void {
        this.boundingBox.next(this.boundingBox.value.setFromObject(this.root)
            .applyMatrix4(this.root.matrixWorld.clone().invert())); // boundingBox is world transform invariant
    }

    override dispose(): void {
        this._clearSelection();
    }
}
