import {
    Float32BufferAttribute,
    InstancedBufferAttribute,
    InstancedBufferGeometry,
    InstancedInterleavedBuffer,
    InterleavedBufferAttribute, Matrix4, Uint8BufferAttribute, Vector2, Vector3
} from "three";
import {Box3} from "three";
import {Sphere} from "three";
import {TypedRanges} from "../Rendering/DisplayGroup.js";

const positions = [ - 1, 2, 0, 1, 2, 0, - 1, 1, 0, 1, 1, 0, - 1, 0, 0, 1, 0, 0, - 1, - 1, 0, 1, - 1, 0 ];
const uvs = [ - 1, 2, 1, 2, - 1, 1, 1, 1, - 1, - 1, 1, - 1, - 1, - 2, 1, - 2 ];
const index = [ 0, 2, 1, 2, 3, 1, 2, 4, 3, 4, 5, 3, 4, 6, 5, 6, 7, 5 ];

const positionAttribute = new Float32BufferAttribute(positions, 3);
const uvAttribute = new Float32BufferAttribute(uvs, 2);
const indexAttribute = new Uint8BufferAttribute(index, 1);

const _vector = new Vector3();
const _box = new Box3();

export class MeshLineGeometry extends InstancedBufferGeometry {
    isMeshLineGeometry = true;

    constructor() {
        super();
        this.init();
    }

    private init(): void {
        this.setIndex(indexAttribute);
        this.setAttribute('position', positionAttribute);
        this.setAttribute('uv', uvAttribute);
    }

    get instanceStartAttribute(): InterleavedBufferAttribute {
        return this.getAttribute('instanceStart') as InterleavedBufferAttribute;
    }

    get instanceEndAttribute(): InterleavedBufferAttribute {
        return this.getAttribute('instanceEnd') as InterleavedBufferAttribute;
    }

    get instanceDistanceStartAttribute(): InterleavedBufferAttribute {
        return this.getAttribute('instanceDistanceStart') as InterleavedBufferAttribute;
    }

    get instanceDistanceEndAttribute(): InterleavedBufferAttribute {
        return this.getAttribute('instanceDistanceEnd') as InterleavedBufferAttribute;
    }

    get instanceColorStartAttribute(): InterleavedBufferAttribute {
        return this.getAttribute('instanceColorStart') as InterleavedBufferAttribute;
    }

    get instanceColorEndAttribute(): InterleavedBufferAttribute {
        return this.getAttribute('instanceColorEnd') as InterleavedBufferAttribute;
    }

    get visibilityMaskAttribute(): InstancedBufferAttribute {
        return this.getAttribute('visibilityMask') as InstancedBufferAttribute;
    }

    /**
     * Requires pairs of vertices to render individual segments (duplicated vertices for non-breaking line)
     */
    setPositions(positions: Float32Array, onUpload?: () => void): void {
        let startAttribute = this.instanceStartAttribute;
        let endAttribute = this.instanceEndAttribute;
        let instanceBuffer: InstancedInterleavedBuffer;

        if (startAttribute) {
            if (startAttribute.data.array.length === positions.length) {
                // if data length is same, update the buffer
                instanceBuffer = startAttribute.data as InstancedInterleavedBuffer;
                instanceBuffer.array = positions;
                instanceBuffer.needsUpdate = true;
            } else {
                // if data length is different, dispose previous attributes and create new ones
                startAttribute = undefined;
                endAttribute = undefined;
                // remove static attributes to avoid their disposal, will be reassigned in init()
                this.index = null;
                delete this.attributes.position;
                delete this.attributes.uv;
                delete this.attributes.visibilityMask;
                this.dispose();
                this.init();
            }
        }

        if (!startAttribute) {
            instanceBuffer = new InstancedInterleavedBuffer(positions, 6, 1);
            startAttribute = new InterleavedBufferAttribute(instanceBuffer, 3, 0);
            endAttribute = new InterleavedBufferAttribute(instanceBuffer, 3, 3);
        }

        // @ts-ignore
        if (onUpload) instanceBuffer.onUploadCallback = onUpload;
        this.setAttribute('instanceStart', startAttribute);
        this.setAttribute('instanceEnd', endAttribute);
    }

    setColors(colors: Uint8Array, onUpload?: () => void): void {
        let startAttribute = this.instanceColorStartAttribute;
        let endAttribute = this.instanceColorEndAttribute;
        let instanceBuffer: InstancedInterleavedBuffer;

        if (startAttribute) {
            if (startAttribute.data.array.length === colors.length) {
                // if data length is same, update the buffer
                instanceBuffer = startAttribute.data as InstancedInterleavedBuffer;
                instanceBuffer.array = colors;
                instanceBuffer.needsUpdate = true;
            } else {
                throw new Error("Colors buffer length changed");
            }
        }

        if (!startAttribute) {
            instanceBuffer = new InstancedInterleavedBuffer(colors, 6, 1);
            startAttribute = new InterleavedBufferAttribute(instanceBuffer, 3, 0, true);
            endAttribute = new InterleavedBufferAttribute(instanceBuffer, 3, 3, true);
        }

        // @ts-ignore
        if (onUpload) instanceBuffer.onUploadCallback = onUpload;
        this.setAttribute('instanceColorStart', startAttribute);
        this.setAttribute('instanceColorEnd', endAttribute);
    }

    /**
     * Converts line vertex vectors to vertex pairs for segment rendering
     */
    setLinePoints(points: Vector3[] | Vector2[]): void {
        // converts [ x1, y1, z1,  x2, y2, z2, ... ] to pairs format
        const positions = new Float32Array((points.length - 1) * 3 * 2);
        for (let i = 0; i < points.length - 1; i++ ) {
            const pi = i * 3 * 2;
            positions[ pi ] = points[i].x;
            positions[ pi + 1 ] = points[i].y;
            positions[ pi + 2 ] = (points[i] as Vector3).z || 0;

            positions[ pi + 3 ] = points[i + 1].x;
            positions[ pi + 4 ] = points[i + 1].y;
            positions[ pi + 5 ] = (points[i + 1] as Vector3).z || 0;
        }
        this.setPositions(positions);
    }

    /**
     * Converts line vertex positions to vertex pairs for segment rendering
     */
    setLinePositions(array: ArrayLike<number>): void {
        // converts [ x1, y1, z1,  x2, y2, z2, ... ] to pairs format
        const length = array.length - 3;
        const positions = new Float32Array( 2 * length );

        for (let i = 0; i < length; i += 3) {
            positions[ 2 * i ] = array[ i ];
            positions[ 2 * i + 1 ] = array[ i + 1 ];
            positions[ 2 * i + 2 ] = array[ i + 2 ];
            positions[ 2 * i + 3 ] = array[ i + 3 ];
            positions[ 2 * i + 4 ] = array[ i + 4 ];
            positions[ 2 * i + 5 ] = array[ i + 5 ];
        }
        this.setPositions(positions);
    }

    /**
     * Sets individual visibility of line segments
     * @param visibility A TypedRanges object for individual visibility, true for all visible or false for all invisible
     */
    setVisibilityMask(visibility: boolean | TypedRanges): void {
        const startAttribute = this.instanceStartAttribute;
        if (!startAttribute) throw new Error("Geometry was not built");
        const n = startAttribute.count;

        let attr = this.visibilityMaskAttribute;
        if (attr) {
            (attr.array as Uint8Array).fill(0);
        } else {
            attr = new InstancedBufferAttribute(new Uint8Array(n), 1);
            this.setAttribute('visibilityMask', attr);
        }

        if (visibility === true) (attr.array as Uint8Array).fill(1);
        else if (visibility) this.addVisibilityMask(visibility as TypedRanges);
        attr.needsUpdate = true;
    }

    /**
     * Adds individual visibility of line segments (OR), without affecting the visibility of other segments
     * @param ranges A TypedRanges object
     */
    addVisibilityMask(ranges: TypedRanges): void {
        if (!this.hasVisibilityMask) throw new Error("Visibility attribute was not initialised");
        const attr = this.visibilityMaskAttribute;
        const arr = attr.array as Uint8Array;

        for (let i = 0; i < ranges.starts.length; ++i) {
            const start = ranges.starts[i] / 2;
            const count = ranges.counts[i] / 2;
            if (count === 0xffffffff) arr.fill(1, start); // Fill the rest
            else arr.fill(1, start, start + count);
        }
        attr.needsUpdate = true;
    }

    get hasVisibilityMask(): boolean {
        return !!this.visibilityMaskAttribute;
    }

    override addGroup(start: number, count: number, materialIndex: number): void {
        // line segments are rendered with instancing, so geometry group can not be used to render partly
        this.groups.push({
            start: 0,
            count: Infinity,
            instanceOffset: start / 2,
            instanceCount: count / 2,
            materialIndex: materialIndex
        } as any);
    }

    override applyMatrix4(matrix: Matrix4): this {
        this.instanceStartAttribute.applyMatrix4(matrix);
        this.instanceEndAttribute.applyMatrix4(matrix);
        if (this.boundingBox)
            this.computeBoundingBox();
        if (this.boundingSphere)
            this.computeBoundingSphere();
        return this;
    }

    override computeBoundingBox(): void {
        if (!this.boundingBox)
            this.boundingBox = new Box3();

        const position = this.instanceStartAttribute;
        if (position) {
            this.boundingBox.setFromArray(position.data.array);
        } else {
            this.boundingBox.makeEmpty();
        }
    }

    override computeBoundingSphere(): void {
        if (!this.boundingSphere)
            this.boundingSphere = new Sphere();

        const position = this.instanceStartAttribute;
        if (position) {
            // first, find the center of the bounding sphere
            const center = this.boundingSphere.center;
            _box.setFromArray(position.data.array);
            _box.getCenter(center);

            // second, try to find a boundingSphere with a radius smaller than the
            // boundingSphere of the boundingBox: sqrt(3) smaller in the best case
            let maxRadiusSq = 0;

            for ( let i = 0, il = position.data.array.length; i < il; i +=3 ) {
                _vector.fromArray(position.data.array, i);
                maxRadiusSq = Math.max( maxRadiusSq, center.distanceToSquared(_vector) );
            }
            this.boundingSphere.radius = Math.sqrt( maxRadiusSq );
        }
    }

    computeLineDistances(): void {
        const start = new Vector3();
        const end = new Vector3();
        const instanceStart = this.instanceStartAttribute;
        const instanceEnd = this.instanceEndAttribute;
        const lineDistances = this.instanceDistanceStartAttribute && this.instanceDistanceStartAttribute.count === instanceStart.count ?
            this.instanceDistanceStartAttribute.array : new Float32Array(2 * instanceStart.count);

        for ( let i = 0, j = 0, l = instanceStart.count; i < l; i ++, j += 2 ) {
            start.fromBufferAttribute(instanceStart, i);
            end.fromBufferAttribute(instanceEnd, i);
            lineDistances[ j ] = ( j === 0 ) ? 0 : lineDistances[ j - 1 ];
            lineDistances[ j + 1 ] = lineDistances[ j ] + start.distanceTo( end );
        }
        const instanceDistanceBuffer = new InstancedInterleavedBuffer(lineDistances, 2, 1); // d0, d1
        this.setAttribute('instanceDistanceStart', new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 0)); // d0
        this.setAttribute('instanceDistanceEnd', new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 1)); // d1
    }

    override setFromPoints(points: Vector3[] | Vector2[]): this {
        throw new Error("Not implemented");
    }

    override computeVertexNormals(): void {
        throw new Error("Not implemented");
    }

    override computeTangents(): void {
        throw new Error("Not implemented");
    }

    override toNonIndexed(): this {
        throw new Error("Not implemented");
    }

    override normalizeNormals(): void {
        throw new Error("Not implemented");
    }
}
