import {Color, Material, ShaderMaterialParameters} from "three";
import { DepthPeelingMaterial } from "./DepthPeelingMaterial.js";
import { GlobalMaterialUniforms } from "./RenderingManager.js";

export class Web3DMaterial extends DepthPeelingMaterial {
    // We need this boolean as rollup copies this class for each plugin, so instanceof doesnt work :(
    readonly isWVMaterial = true;

    setIntersection(state: boolean): void {
        this.clipIntersection = state;
        this.needsUpdate = true;
    }

    get color(): Color {
        return this.uniforms.diffuse.value;
    }

    set color(color: Color) {
        this.uniforms.diffuse.value = color;
    }

    protected constructor(parameters: ShaderMaterialParameters, globalUniforms: GlobalMaterialUniforms) {
        super(parameters, globalUniforms);
        this.clipping = true;
        this.lights = false;
    }

    override clone(): this {
        throw new Error("Material is not cloneable");
    }

    override copy(material: Web3DMaterial): this {
        throw new Error("Material can not be copied");
    }
}
