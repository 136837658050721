import {CustomLengthFormatter, LengthUnit} from "../common.js";
import {
    Box3,
    BufferGeometry,
    Camera,
    ClampToEdgeWrapping,
    Color,
    ConeGeometry,
    Euler, Frustum,
    InstancedBufferAttribute,
    LinearFilter,
    Material,
    Matrix4,
    Mesh,
    Object3D,
    Ray,
    Texture,
    Vector2,
    Vector3
} from "three";
import {IIntersection} from "../Picker/IIntersection.js";
import {Caster} from "../Picker/Caster.js";
import {Api} from "../Api.js";
import {TypedArray} from "./common-utils.js";
import {Web3DMeshPointsMaterial} from "../Rendering/Web3DMeshPointsMaterial.js";

export function getBoxCorners(box: Box3): Vector3[] {
    const min = box.min.x === Infinity ? new Vector3(-50, -50, -50) : box.min;
    const max = box.max.x === -Infinity ? new Vector3(50, 50, 50) : box.max;
    const corners: Vector3[] = [];

    /*
      5____4
	1/___0/|
	| 6__|_7
	2/___3/
	0: max.x, max.y, max.z
	1: min.x, max.y, max.z
	2: min.x, min.y, max.z
	3: max.x, min.y, max.z
	4: max.x, max.y, min.z
	5: min.x, max.y, min.z
	6: min.x, min.y, min.z
	7: max.x, min.y, min.z
	*/

    corners.push(new Vector3(max.x, max.y, max.z));
    corners.push(new Vector3(min.x, max.y, max.z));
    corners.push(new Vector3(min.x, min.y, max.z));
    corners.push(new Vector3(max.x, min.y, max.z));

    corners.push(new Vector3(max.x, max.y, min.z));
    corners.push(new Vector3(min.x, max.y, min.z));
    corners.push(new Vector3(min.x, min.y, min.z));
    corners.push(new Vector3(max.x, min.y, min.z));

    return corners;
}

export function DirectionToSpherical(direction: Vector3, up: Vector3, target?: Vector2): Vector2 {
    let y: number;

    if (Math.abs(direction.x) < 0.002 && Math.abs(direction.y) < 0.002) {
        if (direction.z > 0) {
            y = Math.PI + calculateSphericalYAngle(up);
        } else {
            y = calculateSphericalYAngle(up);
        }
    } else {
        y = Math.atan2(direction.y, direction.x);
    }

    if (!target) target = new Vector2();
    return target.set(Math.acos(direction.z), y);
}

export function calculateSphericalYAngle(up: Vector3): number {
    if (up.x === 0.0) {
        if (up.y > 0) {
            return Math.PI / 2;
        } else {
            return -Math.PI / 2;
        }
    } else if (up.y === 0) {
        if (up.x > 0) {
            return 0.0;
        } else {
            return Math.PI;
        }
    } else {
        const angle = Math.atan2(Math.abs(up.y), Math.abs(up.x));

        if (up.x < 0) {
            if (up.y < 0) {
                return angle + Math.PI;
            } else {
                return angle + Math.PI / 2;
            }
        } else {
            if (up.y < 0) {
                return angle + 1.5 * Math.PI;
            } else {
                return angle;
            }
        }
    }
}

export function SphericalToDirection(sphericalCoordinates: Vector2, vec: Vector3): Vector3 {
    return vec.set(
        Math.sin(sphericalCoordinates.x) * Math.cos(sphericalCoordinates.y),
        Math.sin(sphericalCoordinates.x) * Math.sin(sphericalCoordinates.y),
        Math.cos(sphericalCoordinates.x)
    ).normalize();
}

export function closestPointBetweenRays(line0: Ray, line1: Ray): Vector3 {
    const originDiff = new Vector3().subVectors(line0.origin, line1.origin);
    const a01 = -line0.direction.dot(line1.direction);

    const b0 = originDiff.dot(line0.direction);
    const c = originDiff.length();
    const determinant = Math.abs(1.0 - a01 * a01);
    let line0Parameter: number;

    if (determinant >= 0) {
        // lines are not parallel
        const fB1 = -originDiff.dot(line1.direction);
        const inverseDeterminant = 1.0 / determinant;
        line0Parameter = (a01 * fB1 - b0) * inverseDeterminant;
    } else {
        // lines are parallel, select any closest pair of points
        line0Parameter = -b0;
    }

    return new Vector3()
        .copy(line0.origin)
        .add(line0.direction.multiplyScalar(line0Parameter));
}

export function getTouchPoint(event: TouchEvent): { x: number; y: number } {
    const point = { x: 0, y: 0 };
    const touches = event.touches.length !== 0 ? event.touches : event.changedTouches;
    for (const touch of touches) {
        point.x += touch.clientX;
        point.y += touch.clientY;
    }
    point.x /= touches.length;
    point.y /= touches.length;
    return point;
}

export function getRayIntersection(x: number, y: number, camera: Camera): IIntersection {
    const vector = new Vector3();
    const raycaster = new Caster();

    vector.set((x / window.innerWidth) * 2 - 1, -(y / window.innerHeight) * 2 + 1, 0.5);

    vector.unproject(camera);

    const dir = vector.sub(camera.position).normalize();
    raycaster.set(camera.position, dir);
    return {
        id: null,
        object: null,
        model: null,
        caster: raycaster
    };
}

export function rayToWorldPosition(ray: Ray, camera: Camera, point: Vector3): Vector3 {
    const dir = ray.direction;
    const distance = camera.position.distanceTo(point);
    return camera.position.clone().add(dir.multiplyScalar(distance));
}

export function copyToVector3(from: Vector3, to: Vector3): Vector3 {
    to.x = from.x; to.y = from.y; to.z = from.z;
    return to;
}

export const MILLIMETERS_IN_FOOT = 304.8;
export const MILLIMETERS_IN_INCH = 25.4;

export const lengthUnits: Record<
    LengthUnit,
    { inMillimeters: number; symbol: string }
> = {
    mm: { inMillimeters: 1, symbol: "mm" },
    cm: { inMillimeters: 1e1, symbol: "cm" },
    m: { inMillimeters: 1e3, symbol: "m" },
    km: { inMillimeters: 1e6, symbol: "km" },
    ft: { inMillimeters: MILLIMETERS_IN_FOOT, symbol: "ft" },
    in: { inMillimeters: MILLIMETERS_IN_INCH, symbol: "in" },
    yd: { inMillimeters: 914.4, symbol: "yd" },
    mi: { inMillimeters: 1609344, symbol: "mi" },
    custom: { inMillimeters: 1e3, symbol: "m" }
};

export function formatLength(
    millimeters: number,
    unit: LengthUnit,
    decimals: number = 2
): string {
    const value = lengthUnits[unit];
    return `${roundToDecimals(millimeters / value.inMillimeters, decimals)} ${value.symbol}`;
}

function roundToDecimals(value: number, decimals: number): number {
    const p = Math.pow(10, decimals);
    return Math.round(value * p) / p;
}

export const distanceFormatter = (lengthUnit: LengthUnit, decimals: number) => {
    return (distance: number) => {
        return formatLength(distance * 1000, lengthUnit, decimals);
    };
};

export const positionFormatter = (position: Vector3, lengthFormatter: CustomLengthFormatter) => {
    return `X ${lengthFormatter(position.x)} | Y ${lengthFormatter(position.y)} | Z ${lengthFormatter(position.z)}`;
};

export function createGeometryInstancedAttribute(geometry: BufferGeometry, attributeName: string, length: number, itemSize: number, arrayType: new (size: number) => TypedArray): InstancedBufferAttribute {
    let array: TypedArray;
    let attribute = geometry.getAttribute(attributeName) as InstancedBufferAttribute;

    // minimize the number of array allocations, reallocate only if too small or 2x size
    if (attribute && attribute.array.length >= length * itemSize && attribute.array.length < length * itemSize * 2) {
        (attribute.count as any) = length;
        attribute.clearUpdateRanges();
        attribute.addUpdateRange(0, length * itemSize);
        attribute.needsUpdate = true;
    }
    else {
        array = new arrayType(length * itemSize);
        attribute = new InstancedBufferAttribute(array, itemSize);
        geometry.setAttribute(attributeName, attribute);
    }
    return attribute;
}

export function getFileBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = event => {
            const target = <any>event.target;
            resolve(target.result);
        };

        reader.onerror = e => {
            console.error(e);
            return reject(e);
        };

        reader.readAsArrayBuffer(file);
    });
}

export function toImage(blob: Blob | HTMLCanvasElement): Promise<ImageBitmap> {
    return createImageBitmap(blob, { imageOrientation: "flipY" });
}

const v1 = new Vector3();
const v2 = new Vector3();
export function createScreenStaticSizeMesh(geometry: BufferGeometry, material: Material, api: Api, adjustScale?: (scale: number) => number): Mesh {
    const marker = new Mesh(geometry, material as any);
    makeScreenStaticSize(marker, api, adjustScale);
    return marker;
}

export function makeScreenStaticSize(object: Object3D, api: Api, adjustScale?: (scale: number) => number): void {
    object.renderOrder = 1;

    let mesh: Mesh = undefined;
    object.traverse(o => { if (o instanceof Mesh) mesh = o as Mesh; });
    mesh.onBeforeRender = () => {
        let scale = api.camera.getProjectionCompensatingScale(object.getWorldPosition(v1).distanceTo(api.camera.getWorldPosition(v2)));
        if (adjustScale) scale = adjustScale(scale);
        object.scale.set(scale, scale, scale);
        object.updateMatrixWorld(true);
    };
}

export function createArrowGeometry(size: number): BufferGeometry {
    const geometry = new ConeGeometry(size / 3, size, 10);
    geometry.applyMatrix4(new Matrix4().makeRotationFromEuler(new Euler(-Math.PI / 2, 0, 0)).setPosition(0, 0, size / 2));
    return geometry;
}

export function loadPointIconTexture(url: string): Texture {
    const t = new Texture();
    t.premultiplyAlpha = false;
    t.generateMipmaps = false;
    t.minFilter = LinearFilter;
    t.magFilter = LinearFilter;
    t.wrapS = ClampToEdgeWrapping;
    t.wrapT = ClampToEdgeWrapping;
    (async (): Promise<void> => {
        t.image = await createImageBitmap(await (await fetch(url)).blob(), {imageOrientation: "flipY", premultiplyAlpha: "none"});
        t.needsUpdate = true;
    })();
    return t;
}

export function createMeshPointIconMaterial(api: Api, texture: Texture, size: number, color?: Color): Web3DMeshPointsMaterial {
    return new Web3DMeshPointsMaterial({
        size: size,
        sizeAttenuation: false,
        map: texture ? texture : null,
        depthTest: false,
        transparent: true,
        color: color ? color : null,
        usePointUv: false,
    }, api.renderingManager.uniforms);
}

 export function getRootWorkerUrl(settings: { workerUrl?: string}): string {
    if (settings && settings.workerUrl)
        return settings.workerUrl;
    // @ts-ignore
    return (window.DEFAULT_STATIC_ROOT_URL || "/") + "dist/workers/"; // will be replaced with import.meta.url by rollup
}

export function calculateLuminance(color: Color): number {
    const c = new Color().copySRGBToLinear(color);
    return 0.299*c.r + 0.587*c.g + 0.114*c.b;
}

export function calculateIntersectionOfLines(L1S: Vector3, L1E: Vector3, L2S: Vector3, L2E: Vector3): Vector3[] {
    const c1 =
        (
            ( (L1S.x - L2S.x) * (L2E.x - L2S.x) + (L1S.y - L2S.y) * (L2E.y - L2S.y) + (L1S.z - L2S.z) * (L2E.z - L2S.z) ) *
            ( (L2E.x - L2S.x) * (L1E.x - L1S.x) + (L2E.y - L2S.y) * (L1E.y - L1S.y) + (L2E.z - L2S.z) * (L1E.z - L1S.z) )
            -
            ( (L1S.x - L2S.x) * (L1E.x - L1S.x) + (L1S.y - L2S.y) * (L1E.y - L1S.y) + (L1S.z - L2S.z) * (L1E.z - L1S.z) ) *
            ( (L2E.x - L2S.x) * (L2E.x - L2S.x) + (L2E.y - L2S.y) * (L2E.y - L2S.y) + (L2E.z - L2S.z) * (L2E.z - L2S.z) )
        ) /
        (
            ( (L1E.x - L1S.x) * (L1E.x - L1S.x) + (L1E.y - L1S.y) * (L1E.y - L1S.y) + (L1E.z - L1S.z) * (L1E.z - L1S.z) ) *
            ( (L2E.x - L2S.x) * (L2E.x - L2S.x) + (L2E.y - L2S.y) * (L2E.y - L2S.y) + (L2E.z - L2S.z) * (L2E.z - L2S.z) )
            -
            ( (L2E.x - L2S.x) * (L1E.x - L1S.x) + (L2E.y - L2S.y) * (L1E.y - L1S.y) + (L2E.z - L2S.z) * (L1E.z - L1S.z) ) *
            ( (L2E.x - L2S.x) * (L1E.x - L1S.x) + (L2E.y - L2S.y) * (L1E.y - L1S.y) + (L2E.z - L2S.z) * (L1E.z - L1S.z) )
        );

    if (isNaN(c1) || c1 === Infinity || c1 === -Infinity)
        return undefined;

    const c2 =
        (
            ( (L1S.x - L2S.x) * (L2E.x - L2S.x) + (L1S.y - L2S.y) * (L2E.y - L2S.y) + (L1S.z - L2S.z) * (L2E.z - L2S.z) )
            +
            ( c1 * ( (L2E.x - L2S.x) * (L1E.x - L1S.x) + (L2E.y - L2S.y) * (L1E.y - L1S.y) + (L2E.z - L2S.z) * (L1E.z - L1S.z) ) )
        ) /
        (
            (L2E.x - L2S.x) * (L2E.x - L2S.x) + (L2E.y - L2S.y) * (L2E.y - L2S.y) + (L2E.z - L2S.z) * (L2E.z - L2S.z)
        );
    if (isNaN(c2) || c1 === Infinity || c1 === -Infinity)
        return undefined;

    const point1 = L1S.clone().add( (L1E.clone().sub(L1S)).multiplyScalar(c1) );
    const point2 = L2S.clone().add( (L2E.clone().sub(L2S)).multiplyScalar(c2) );

    return [point1, point2];
}

export function isFrustumFinite(frustum: Frustum): boolean {
    for (let i = 0; i < frustum.planes.length; i++) {
        const plane = frustum.planes[i];
        if (![plane.normal.x, plane.normal.y, plane.normal.z, plane.constant].every(Number.isFinite)) {
            return false; // Frustum contains non-finite values
        }
    }
    return true; // All values in the frustum are finite
}