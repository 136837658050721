import {Box3, Object3D} from "three";
import {Api} from "@technology/web3d/dist/esnext/Api.js";
import {GenericModel} from "@technology/web3d/dist/esnext/GenericModel.js";

export class ThreejsModel extends GenericModel {

    constructor(api: Api) {
        super("threejs", api);
    }

    add = (() =>{
        const box = new Box3();
        const accumulatedBox = new Box3();

        return (...object: Object3D[]): void => {
            this.root.add(...object);
            accumulatedBox.makeEmpty();
            for (const o of object) {
                box.setFromObject(o);   // Sets box from o including o's children
                accumulatedBox.union(box);
            }
            this.boundingBox.next(this.boundingBox.value.union(accumulatedBox));
        };
    })();

    remove(...object: Object3D[]): void {
        this.root.remove(...object);
    }

    clear(): void {
        this.boundingBox.next(this.boundingBox.value.makeEmpty());
        this.root.clear();
    }
}
