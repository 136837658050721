import {
    LinearFilter,
    RedFormat,
    RGBAFormat,
    Texture,
} from "three";
import {toImage} from "./utils.js";

interface DrawStyle {
    margin?: number;
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
}

export interface TextDescription extends DrawStyle {
    text: string;
    font: string;
    size: number;
    sizeUnit?: string; // CSS font size unit. NB: Currently, relative units cannot be used with multiline texts.
    weight?: string;
}

interface AtlasDefinition {
    texture: Texture;
    canvas?: HTMLCanvasElement;
    size: number;
}
export interface AtlasSample {
    atlasIndex: number;
    offsetX: number;
    offsetY: number;
    width: number;
    height: number;
}
export interface AtlasRepresentation {
    atlases: AtlasDefinition[];
    samples: AtlasSample[];
}

enum DrawCommandType { TEXT, RECTANGLE, IMAGE }
interface DrawCommand {
    type: DrawCommandType;
    x: number;
    y: number;
}
interface DrawTextCommand extends DrawCommand {
    type: DrawCommandType.TEXT;
    text: string;
    fontString: string;
    color?: string;
}
interface DrawRectangleCommand extends DrawCommand, DrawStyle {
    type: DrawCommandType.RECTANGLE;
    width: number;
    height: number;
}
interface DrawImageCommand extends DrawCommand {
    type: DrawCommandType.IMAGE;
    image: CanvasImageSource;
}

// TODO: Rename to TextureGenerator
export abstract class AbstractTextureGenerator {

    lineSpace = 1.2;
    scaleByPixelRatio = true;

    protected static readonly ATLAS_MARGIN = 4;
    protected readonly measurementContext = document.createElement('canvas').getContext('2d');

    get pixelRatio(): number {
        return this.scaleByPixelRatio ? devicePixelRatio : 1;
    }

    protected calculateSize(td: TextDescription): { width: number, height: number, bbAscent: number } {
        const lines = td.text.split(/\r?\n/);
        const scaledSize = td.size * this.pixelRatio;
        this.measurementContext.font = this.getFontString(td);
        const metrics = this.measurementContext.measureText(td.text);

        // NB: Line height is only correct with absolute font sizes. If relative font sizes with multiline texts are
        // needed, this logic needs to be rethought.
        let lineHeight = scaledSize * this.lineSpace;
        let bbAscent = scaledSize;

        const localMargin = td.margin || 0;

        // Calculating the correct measures for exact BB alignment (not supported in FF & Edge):
        if ("actualBoundingBoxAscent" in metrics && "actualBoundingBoxDescent" in metrics) {
            bbAscent = metrics.actualBoundingBoxAscent;
            lineHeight = bbAscent + metrics.actualBoundingBoxDescent;
        }

        const height = scaledSize * 1.2 * (lines.length - 1) + lineHeight + 2*localMargin;

        // The width of the texture is the width of the widest row:
        let width = 0;
        for (const l of lines) {
            const w = this.measurementContext.measureText(l).width;
            if (w > width) width = w;
        }
        width += 2*localMargin;
        
        return { width, height, bbAscent };
    }

    protected getFontString(td: TextDescription): string {
        const scaledSize = td.size * this.pixelRatio;
        return `${ td.weight || "" } ${ scaledSize }${ td.sizeUnit || "px" } ${ td.font }`;
    }

    protected static draw(ctx: CanvasRenderingContext2D, cmd: DrawCommand): void {
        switch (cmd.type) {

            case DrawCommandType.RECTANGLE: {
                const rc = cmd as DrawRectangleCommand;
                if (rc.backgroundColor) {
                    ctx.fillStyle = rc.backgroundColor;
                    ctx.fillRect(rc.x, rc.y, rc.width, rc.height);
                }
                if (rc.borderColor) {
                    ctx.strokeStyle = rc.borderColor;
                    ctx.lineWidth = rc.borderWidth || 1;
                    ctx.strokeRect(rc.x, rc.y, rc.width, rc.height);
                }
            } break;

            case DrawCommandType.TEXT: {
                const tc = cmd as DrawTextCommand;
                ctx.fillStyle = tc.color || "#ffffff";
                ctx.font = tc.fontString;
                ctx.fillText(tc.text, tc.x, tc.y);
            } break;

            case DrawCommandType.IMAGE: {
                const ic = cmd as DrawImageCommand;
                ctx.drawImage(ic.image, ic.x, ic.y);
            } break;

            default:
                throw new Error("Unknown draw command type");
        }
    }
}

// TODO: Rename to StaticTextureGenerator
export class TextureGenerator extends AbstractTextureGenerator {

    private textures: AtlasRepresentation;
    private atlasIndex: number = 0;

    static readonly MAX_ATLAS_SIZE = 2048;
    private atlasCanvas: HTMLCanvasElement;
    private samplesForCurrentAtlas: number;
    private offsetX: number;
    private offsetY: number;
    private boundingBoxX: number;
    private boundingBoxY: number;

    generateImageAtlases(images: CanvasImageSource[]): AtlasRepresentation {
        this.resetWorkingState();

        const drawCommands = new Array<DrawCommand>();

        for (const image of images) {
            if (image instanceof VideoFrame || image instanceof SVGImageElement) continue; // Not supported
            const width = image.width;
            const height = image.height;

            this.fitSample(width, height, drawCommands, false);

            // Create one image draw command:
            drawCommands.push({
                type: DrawCommandType.IMAGE,
                image,
                x: this.offsetX,
                y: this.offsetY,
            } as DrawImageCommand);

            this.registerSample(width, height);
        }

        // If current atlas contains textures, store it as a texture:
        if (this.samplesForCurrentAtlas > 0) this.makeAtlasTexture(drawCommands, false);

        return this.textures;
    }

    generateTextAtlases(textDescriptions: Array<TextDescription>, singleChannel: boolean = true): AtlasRepresentation {
        this.resetWorkingState();

        const drawCommands = new Array<DrawCommand>();

        for (const td of textDescriptions) {
            const { width, height, bbAscent } = this.calculateSize(td);
            const scaledSize = td.size * this.pixelRatio;
            const localMargin = td.margin || 0;
            const lines = td.text.split(/\r?\n/);

            this.fitSample(width, height, drawCommands, singleChannel);

            // If background or border is requested, create a rectangle draw command:
            if (td.backgroundColor || td.borderColor)
                drawCommands.push({
                    type: DrawCommandType.RECTANGLE,
                    x: this.offsetX + localMargin + 1,
                    y: this.offsetY + localMargin + 1,
                    width,
                    height,
                    margin: localMargin,
                    backgroundColor: td.backgroundColor,
                    borderColor: td.borderColor,
                    borderWidth: td.borderWidth,
                } as DrawRectangleCommand);

            // Create one text draw command per line:
            for (let i = 0; i < lines.length; ++i)
                drawCommands.push({
                    type: DrawCommandType.TEXT,
                    fontString: this.getFontString(td),
                    text: lines[i],
                    x: this.offsetX + localMargin + 1,
                    y: this.offsetY + bbAscent + i * scaledSize * this.lineSpace + localMargin + 1,
                    color: td.color,
                } as DrawTextCommand);

            this.registerSample(width, height);
        }

        // If current atlas contains textures, store it as a texture:
        if (this.samplesForCurrentAtlas > 0) this.makeAtlasTexture(drawCommands, singleChannel);

        return this.textures;
    }

    private resetWorkingState(): void {
        this.textures = { atlases: [], samples: [] } as AtlasRepresentation;
        this.atlasIndex = 0;
        this.resetCanvas();
    }

    private fitSample(width: number, height: number, drawCommands: DrawCommand[], singleChannel: boolean): void {
        // If does not fit at current offset, switch to a new row:
        if (!this.fits(width, height, TextureGenerator.ATLAS_MARGIN)) {
            this.offsetX = 0;
            this.offsetY = this.boundingBoxY;
        }

        // If still cannot fit, consider atlas as full, store it as a texture, and start a new one:
        if (!this.fits(width, height, TextureGenerator.ATLAS_MARGIN)) {
            this.makeAtlasTexture(drawCommands, singleChannel);
            this.resetCanvas();
        }
    }

    private registerSample(width: number, height: number): void {
        // Store as an atlas sample:
        this.textures.samples.push(this.createAtlasSample(width, height));

        // Update the atlas object
        this.boundingBoxX = Math.max(this.boundingBoxX, this.offsetX + width + TextureGenerator.ATLAS_MARGIN);
        this.boundingBoxY = Math.max(this.boundingBoxY, this.offsetY + height + TextureGenerator.ATLAS_MARGIN);
        this.offsetX += width + TextureGenerator.ATLAS_MARGIN;
    }

    private resetCanvas(): void {
        this.samplesForCurrentAtlas = 0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.boundingBoxX = 0;
        this.boundingBoxY = 0;
    }

    private fits(width: number, height: number, margin: number): boolean {
        return  this.offsetX + width + margin <= TextureGenerator.MAX_ATLAS_SIZE &&
                this.offsetY + height + margin <= TextureGenerator.MAX_ATLAS_SIZE;
    }

    private createAtlasSample(width: number, height: number): AtlasSample {
        this.samplesForCurrentAtlas++;
        return {
            atlasIndex: this.atlasIndex,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            width: width + 2,
            height: height + 2,
        };
    }

    private makeAtlasTexture(drawCommands: Array<DrawCommand>, singleChannel: boolean): void {

        // Crating a square canvas as small as possible, containing the bounding box of its textures:
        const atlasSize = Math.max(this.boundingBoxX, this.boundingBoxY);
        this.atlasCanvas = document.createElement('canvas');
        this.atlasCanvas.width = atlasSize;
        this.atlasCanvas.height = atlasSize;

        // Drawing the text on the canvas and emptying the command array:
        const ctx = this.atlasCanvas.getContext('2d');
        for (const cmd of drawCommands) AbstractTextureGenerator.draw(ctx, cmd);
        drawCommands.length = 0;

        // Creating the atlas:
        this.textures.atlases.push({
            texture: TextureGenerator.createTextTexture(this.atlasCanvas, singleChannel),
            size: atlasSize,
        });
        this.atlasIndex++;
        disposeOfCanvas(this.atlasCanvas);
        this.atlasCanvas = undefined;
    }

    private static createTextTexture(canvas: HTMLCanvasElement, singleChannel: boolean): Texture {
        const texture = new Texture();
        const disposeImage = () => {
            texture.image.close();
            texture.image = undefined;
        };
        toImage(canvas).then(image => {
            texture.image = image;
            texture.needsUpdate = true;
            texture.onUpdate = disposeImage;
        });
        texture.format = singleChannel ? RedFormat : RGBAFormat;
        texture.minFilter = LinearFilter;
        texture.magFilter = LinearFilter;
        texture.generateMipmaps = false;
        texture.premultiplyAlpha = true;
        return texture;
    }
}

export class DynamicTextureGenerator extends AbstractTextureGenerator {

    // One sample corresponds to one atlas, with the same index:
    private textures: AtlasRepresentation = { atlases: [], samples: [] };

    /**
     * Generates an empty dynamic text texture, ready to be updated.
     * @param singleChannel Whether to use a single colour channel texture (default: true)
     */
    generateTextTexture(singleChannel: boolean = true): AtlasSample {
        const sample = {
            atlasIndex: this.textures.atlases.length,
            offsetX: 0, offsetY: 0,
            width: 0, height: 0,
        } as AtlasSample;

        const atlasCanvas = document.createElement('canvas');

        const atlasTexture = {
            texture: DynamicTextureGenerator.createTextTexture(atlasCanvas, singleChannel),
            canvas: atlasCanvas,
            size: 0,
        } as AtlasDefinition;

        this.textures.atlases.push(atlasTexture);
        this.textures.samples.push(sample);

        return sample;
    }

    /**
     * Updates an existing dynamic text texture.
     * @param atlasID The atlas ID (same as sample ID) of the texture to update
     * @param textDescription The text description to write into the texture
     * @param singleChannel Whether to use a single colour channel texture (default: true)
     */
    updateTextTexture(atlasID: number, textDescription: TextDescription, singleChannel: boolean = true): void {
        const { width, height, bbAscent } = this.calculateSize(textDescription);
        const scaledSize = textDescription.size * this.pixelRatio;
        const localMargin = textDescription.margin || 0;
        const lines = textDescription.text.split(/\r?\n/);

        const drawCommands = new Array<DrawCommand>();

        // If background or border is requested, create a rectangle draw command:
        if (textDescription.backgroundColor || textDescription.borderColor)
            drawCommands.push({
                type: DrawCommandType.RECTANGLE,
                x: 1,
                y: 1,
                width,
                height,
                margin: localMargin,
                backgroundColor: textDescription.backgroundColor,
                borderColor: textDescription.borderColor,
                borderWidth: textDescription.borderWidth,
            } as DrawRectangleCommand);

        // Create one text draw command per line:
        for (let i = 0; i < lines.length; ++i)
            drawCommands.push({
                type: DrawCommandType.TEXT,
                fontString: this.getFontString(textDescription),
                text: lines[i],
                x: localMargin + 1,
                y: bbAscent + i * scaledSize * this.lineSpace + localMargin + 1,
                color: textDescription.color,
            } as DrawTextCommand);

        const sample = this.textures.samples[atlasID];
        sample.width = width + 2;
        sample.height = height + 2;

        const atlas = this.textures.atlases[atlasID];

        // Expand canvas if necessary:
        const bbWidth = sample.width + AbstractTextureGenerator.ATLAS_MARGIN;
        const bbHeight  = sample.width + AbstractTextureGenerator.ATLAS_MARGIN;
        const canvas = atlas.canvas;

        if (bbWidth > canvas.width || bbHeight > canvas.height) {
            // Text no longer fits in canvas, so we resize it with some extra space:
            canvas.width = ~~(bbWidth * 1.3) + 1;
            canvas.height = ~~(bbHeight * 1.3) + 1;
            atlas.size = Math.max(canvas.width, canvas.height);

            // Textures cannot be resized, need to create a new one:
            atlas.texture.dispose();
            atlas.texture = DynamicTextureGenerator.createTextTexture(canvas, singleChannel);
        }

        // CLearing the canvas and drawing the new text:
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (const cmd of drawCommands) AbstractTextureGenerator.draw(ctx, cmd);

        atlas.texture.needsUpdate = true;
    }

    getAtlasTexture(atlasID: number): Texture {
        return this.textures.atlases[atlasID].texture;
    }

    disposeOfText(atlasID: number): void {
        const atlas = this.textures.atlases[atlasID];
        if (!atlas) return;
        atlas.texture.dispose();
        if (atlas.canvas) disposeOfCanvas(atlas.canvas);
        delete this.textures.atlases[atlasID];
        delete this.textures.samples[atlasID];
    }

    dispose(): void {
        for (const sample of this.textures.samples)
            if (sample) this.disposeOfText(sample.atlasIndex);
    }

    private static createTextTexture(canvas: HTMLCanvasElement, singleChannel: boolean): Texture {
        const texture = new Texture(canvas);
        texture.needsUpdate = true;

        texture.format = singleChannel ? RedFormat : RGBAFormat;
        texture.minFilter = LinearFilter;
        texture.magFilter = LinearFilter;
        texture.generateMipmaps = false;
        texture.premultiplyAlpha = true;
        return texture;
    }
}

// Some iOS versions have a bug where canvas elements are not properly garbage collected. Safer to
// dispose of it explicitly:
// Ref: https://bugs.webkit.org/show_bug.cgi?id=195325
const disposeOfCanvas = (canvas: HTMLCanvasElement) => {
    canvas.width = 0;
    canvas.height = 0;
    canvas.remove();
};
