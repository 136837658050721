import {
    Color,
    MaterialParameters,
    RedFormat,
    ShaderLib,
    Texture,
    UniformsUtils, Vector2
} from "three";
import { GlobalMaterialUniforms } from "./RenderingManager.js";
import { Web3DMaterial } from "./Web3DMaterial.js";

/**
 * PLEASE NOTE:
 * On some Mac devices, the maximum gl_PointSize is now too small (64), making WebGL points
 * unsuitable primitives for billboards (other than small grid labels and such). Whereas previously
 * they worked well on all tested devices, this is no longer the case for those Mac devices.
 * Possible workaround would be to use camera-aligned quads instead.
 * Refer to https://webglreport.com/?v=2 , under 'Aliased Point Size Range'
 * and this discussion: https://discourse.threejs.org/t/working-around-the-low-aliased-pointsize-on-the-new-m1-macbook-pros/26819
 */

// language=GLSL
const fragmentShader = `
#include <common>
#include <map_particle_pars_fragment>
#include <clipping_planes_pars_fragment>
#include <depth_peeling_pars_fragment>

uniform vec3 diffuse;
uniform float opacity;

varying vec4 vUv; // vec4(leftUpUV, rightDownUV)

#ifdef USE_SIZEBUFFER 
    varying vec4 vPointSize; // vec4(leftUpXY, rightDownXY) from BillboardGeometryBuilder
    varying float pSize;
#endif

void main() {
    #include <clipping_planes_fragment>
    #include <depth_peeling_fragment>
    
	vec4 diffuseColor = vec4(diffuse, opacity);

    vec2 pointCoord = gl_PointCoord;
    #ifdef FLIP_Y
        pointCoord = vec2(pointCoord.x, 1.0 - pointCoord.y);
    #endif
    #ifdef USE_SIZEBUFFER
        // Normalising the point coord to [-1,1]^2, then multiplying with the point size to obtain pixel coordinate relative to point center:
        vec2 pixelCoord = (pointCoord * 2.0 - 1.0) * 0.5 * pSize;
        
        // Discarding pixels outsize the defined point size. Since inverted point sizes (such as (-1,1) to (1,-1)) have to be supported, we must compare to the min and max of the axis:
        if (pixelCoord.x < min(vPointSize.x, vPointSize.z) ||
            pixelCoord.x > max(vPointSize.x, vPointSize.z) ||
            pixelCoord.y < min(vPointSize.y, vPointSize.w) ||
            pixelCoord.y > max(vPointSize.y, vPointSize.w)
        ) discard;
        
        vec2 realSize = vPointSize.zw - vPointSize.xy;
        vec2 localCoord = (pixelCoord - vPointSize.xy) / realSize;
        vec2 uv = localCoord;
    #else
        vec2 uv = pointCoord;
    #endif
    #ifdef USE_POINTUV
        uv = uv * (vUv.zw - vUv.xy) + vUv.xy;
    #endif
    uv = vec2(uv.x, 1.0 - uv.y);
    
	vec4 mapTexel = texture2D(map, uv);
	#ifdef RED_FORMAT
        mapTexel = vec4(vec3(1.0), mapTexel.r);
	#endif
	diffuseColor *= mapTexel;

    if (diffuseColor.a <= 0.0)
        discard; // better selection effect
    
	gl_FragColor = diffuseColor;
}`;

// language=GLSL
const vertexShader = `
uniform float size;
uniform float pixelRatio;
uniform vec2 offset;
uniform vec2 viewSize;

#include <common>
#include <clipping_planes_pars_vertex>

// vec4(leftUpUV, rightDownUV)
attribute vec4 pointUv;
varying vec4 vUv;

#ifdef USE_SIZEBUFFER
    // vec4(leftUpXY, rightDownXY) from BillboardGeometryBuilder
    attribute vec4 pointSize; 
    varying vec4 vPointSize;

    varying float pSize;
#endif

void main() {
	#include <begin_vertex>
	#include <project_vertex>

    #ifdef USE_SIZEBUFFER
        pSize = abs(max(max(-pointSize.x, -pointSize.y), max(pointSize.z, pointSize.w))) * 2.0;
        vPointSize = pointSize;
    #else
        float pSize = size; 
    #endif

    #ifdef USE_SIZEATTENUATION
		gl_PointSize = viewSize.y * projectionMatrix[1][1] * pSize / gl_Position.w;
        gl_Position.xy += projectionMatrix[1][1] * offset * vec2(viewSize.y / viewSize.x, 1.0);
    #else
		gl_PointSize = pSize * pixelRatio;
        gl_Position.xy += offset * vec2(viewSize.y / viewSize.x, 1.0) * gl_Position.w;
    #endif
    
    vUv = pointUv;
    
    #include <clipping_planes_vertex>
}`;

export interface Web3DPointsMaterialParameters extends MaterialParameters {
    color?: Color | string | number;
    map?: Texture;
    size?: number;
    offset?: Vector2;
    sizeAttenuation?: boolean;
    usePointUv?: boolean;
    useSizeBuffer?: boolean;
    flipY?: boolean;
}

/**
 * @deprecated Use Web3DMeshPointsMaterial instead
 */
export class Web3DPointsMaterial extends Web3DMaterial {

    // Called by WebGLRenderer.setProgram to refresh material before rendering
    get isPointsMaterial(): boolean {

        // if points are attenuated - size is in world units, pixels otherwise
        if (!this.sizeAttenuation && this.offset) {
            const height = this.uniforms.viewSize.value.y;
            (this.uniforms.offset.value as Vector2)
                .copy(this.offset)
                .multiplyScalar(window.devicePixelRatio / height);

        } else if (this.offset) {
            if (this._parameters.map.image)
                (this.uniforms.offset.value as Vector2).copy(this.offset);
        }

        this.updateTransparency();
        return false;
    }

    offset: Vector2;
    sizeAttenuation: boolean;

    private _parameters: Web3DPointsMaterialParameters;

    constructor(parameters: Web3DPointsMaterialParameters, globalUniforms: GlobalMaterialUniforms) {
        const uniforms = Object.assign(UniformsUtils.clone(ShaderLib.points.uniforms), {
            diffuse: { value: new Color(parameters.color) },
            map: { type: 't', value: parameters.map },
            offset: { value: new Vector2() },
            size: { value: parameters.size },
            opacity: { value: parameters.opacity ?? 1},
            transparent: {value: parameters.transparent ?? false}
        }, globalUniforms);

        const params = Object.assign({
            uniforms: uniforms,
            defines: {
                USE_MAP: true,
                USE_POINTUV: parameters.usePointUv === undefined ? true : parameters.usePointUv,
                USE_SIZEBUFFER: !!parameters.useSizeBuffer,
                RED_FORMAT: !!(parameters.map && parameters.map.format === RedFormat),
                FLIP_Y: !!parameters.flipY
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader
        }, parameters);
        delete params.color;
        delete params.map;
        delete params.size;
        delete params.sizeAttenuation;
        delete params.usePointUv;
        delete params.useSizeBuffer;
        delete params.offset;
        delete params.flipY;

        super(params, globalUniforms);

        this._parameters = parameters;
        this.offset = parameters.offset;
        this.sizeAttenuation = parameters.sizeAttenuation;
        this.alphaTest = 0.001;
    }

    override clone(): this {
        const m = new Web3DPointsMaterial(this._parameters, this._globalUniforms);
        m.sizeAttenuation = this.sizeAttenuation;
        return m as this;
    }
}
