import {
    Box3, BufferAttribute,
    Float32BufferAttribute,
    InstancedBufferAttribute,
    InstancedBufferGeometry,
    Matrix4,
    Sphere,
    Vector3
} from "three";

/*
 * Each instance is a camera-facing quad:
 *
 * (-1,1)   2---1  (1,1)
 *          | \ |
 * (-1,-1)  3---0  (1,-1)
 */
const positionAttribute = new Float32BufferAttribute([
    1,-1,0, 1,1,0, -1,1,0,
    1,-1,0, -1,1,0, -1,-1,0,
], 3);

const _vector = new Vector3();
const _box = new Box3();

/**
 * Instanced, camera-facing quads. Like Three.js Points, but rendered as an instanced mesh instead of point primitive,
 * to avoid hardware restrictions. The wrapping object should be a Mesh.
 */
export class MeshPointsGeometry extends InstancedBufferGeometry {
    isMeshPointGeometry = true;

    constructor() {
        super();
        this.init();
    }

    private init(): void {
        this.setAttribute('position', positionAttribute);
    }

    get instancePositionAttribute(): BufferAttribute {
        return this.getAttribute('instancePosition') as BufferAttribute;
    }

    setPositions(positions: Float32Array, onUpload?: () => void): void {
        if (this.hasAttribute('instancePosition')) this.clear();
        this.setInstancedAttribute(new InstancedBufferAttribute(positions, 3, false, 1), 'instancePosition', onUpload);
        this.instanceCount = positions.length / 3;
    }

    setPointUv(pointUv: Float32Array, onUpload?: () => void): void {
        this.setInstancedAttribute(new InstancedBufferAttribute(pointUv, 4, false, 1), 'pointUv', onUpload);
    }

    setPointSizes(pointSize: Float32Array, onUpload?: () => void): void {
        this.setInstancedAttribute(new InstancedBufferAttribute(pointSize, 4, false, 1), 'pointSize', onUpload);
    }

    setColors(colors: Uint8Array, onUpload?: () => void): void {
        this.setInstancedAttribute(new InstancedBufferAttribute(colors, 3, true, 1), 'color', onUpload);
    }

    setRotations(rotations: Float32Array, onUpload?: () => void): void {
        this.setInstancedAttribute(new InstancedBufferAttribute(rotations, 1, false, 1), 'rotation', onUpload);
    }

    clear(): void {
        this.deleteAttribute('instancePosition');
        this.deleteAttribute('pointUv');
        this.deleteAttribute('pointSize');
        this.deleteAttribute('color');
        this.deleteAttribute('rotation');

        this.deleteAttribute('position');
        this.dispose();
        this.init();
    }

    private setInstancedAttribute(attribute: InstancedBufferAttribute, name: string, onUpload?: () => void): void {
        attribute.name = name;
        if (onUpload) attribute.onUploadCallback = onUpload;
        this.setAttribute(name, attribute);
    }

    override addGroup(start: number, count: number, materialIndex: number): void {
        // Mesh points are rendered with instancing, so geometry group can not be used to render partly
        this.groups.push({
            start: 0,
            count: Infinity,
            instanceOffset: start,
            instanceCount: count,
            materialIndex: materialIndex
        } as any);
    }

    override applyMatrix4(matrix: Matrix4): this {
        this.instancePositionAttribute.applyMatrix4(matrix);
        if (this.boundingBox)
            this.computeBoundingBox();
        if (this.boundingSphere)
            this.computeBoundingSphere();
        return this;
    }

    override computeBoundingBox(): void {
        if (!this.boundingBox)
            this.boundingBox = new Box3();

        const position = this.instancePositionAttribute;
        if (position) {
            this.boundingBox.setFromArray(position.array);
        } else {
            this.boundingBox.makeEmpty();
        }
    }

    override computeBoundingSphere(): void {
        if (!this.boundingSphere)
            this.boundingSphere = new Sphere();

        const position = this.instancePositionAttribute;
        if (position) {
            // first, find the center of the bounding sphere
            const center = this.boundingSphere.center;
            _box.setFromArray(position.array);
            _box.getCenter(center);

            // second, try to find a boundingSphere with a radius smaller than the
            // boundingSphere of the boundingBox: sqrt(3) smaller in the best case
            let maxRadiusSq = 0;

            for ( let i = 0, il = position.array.length; i < il; i +=3 ) {
                _vector.fromArray(position.array, i);
                maxRadiusSq = Math.max( maxRadiusSq, center.distanceToSquared(_vector) );
            }
            this.boundingSphere.radius = Math.sqrt( maxRadiusSq );
        }
    }
}
