import {Color, DoubleSide, RedFormat, ShaderLib, UniformsUtils, Vector2} from "three";
import {GlobalMaterialUniforms} from "./RenderingManager.js";
import {Web3DPointsMaterialParameters} from "./Web3DPointsMaterial.js";
import {Web3DMaterial} from "./Web3DMaterial.js";

// language=GLSL
const vertexShader = `
    uniform float size;
    uniform float pixelRatio;
    uniform vec2 offset;
    uniform vec2 viewSize;

    attribute vec3 instancePosition;

    #ifdef USE_POINTUV
        attribute vec4 pointUv;
    #else
        vec4 pointUv = vec4(0.0, 0.0, 1.0, 1.0);
    #endif
    
    #ifdef USE_SIZEBUFFER
        attribute vec4 pointSize;
    #endif
    
    varying vec2 vUv;

    #include <common>
    #include <clipping_planes_pars_vertex>

    vec2 getVertexValue(vec4 pointValue, int index) {
        if (index == 0)         return vec2(pointValue.z, pointValue.w);
        else if (index == 1)    return vec2(pointValue.z, pointValue.y);
        else if (index == 2)    return vec2(pointValue.x, pointValue.y);
        else                    return vec2(pointValue.x, pointValue.w);
    }

    void main() {

        /*
         * (-1,1)   2---1  (1,1)
         *          | \ |
         * (-1,-1)  3---0  (1,-1)
         */
        int localIndex = gl_VertexID % 6;
        if (localIndex == 3) localIndex = 0;
        if (localIndex == 4) localIndex = 2;
        if (localIndex == 5) localIndex = 3;

        vec4 mvPosition = modelViewMatrix * vec4(instancePosition, 1.0);
        vec4 pointPos = projectionMatrix * mvPosition;

        #ifdef USE_SIZEBUFFER
            vec2 vertexOffset = 2.0 * getVertexValue(pointSize, localIndex);
            vertexOffset = vec2(vertexOffset.x, -vertexOffset.y);
        #else
            vec2 vertexOffset = position.xy * size;
        #endif
        
        #ifdef USE_SIZEATTENUATION
            vertexOffset *= vec2(viewSize.y / viewSize.x, 1.0) * projectionMatrix[1][1] / pointPos.w;
        #else
            vertexOffset *= pixelRatio / viewSize;
        #endif

        pointPos /= pointPos.w;
        pointPos += vec4(offset, 0.0, 0.0);
        
        gl_Position = pointPos + vec4(vertexOffset, 0.0, 0.0);
        
        vUv = getVertexValue(pointUv, localIndex);
        vUv = vec2(vUv.x, 1.0 - vUv.y); // Correction

        #include <clipping_planes_vertex>
    }
`;

// language=GLSL
const fragmentShader = `
    #include <clipping_planes_pars_fragment>
    #include <depth_peeling_pars_fragment>

    uniform vec3 diffuse;
    uniform float opacity;
    uniform sampler2D map;
    
    varying vec2 vUv;
    
    void main() {
        #include <clipping_planes_fragment>
        #include <depth_peeling_fragment>

        vec4 diffuseColor = vec4(diffuse, opacity);
        
        vec4 mapTexel = texture2D(map, vUv);
        #ifdef RED_FORMAT
            mapTexel = vec4(vec3(1.0), mapTexel.r);
        #endif
        
        diffuseColor *= mapTexel;
        
        if (diffuseColor.a <= 0.0)
            discard; // better selection effect

        gl_FragColor = diffuseColor;
    }
`;

export class Web3DMeshPointsMaterial extends Web3DMaterial {

    // Called by WebGLRenderer.setProgram to refresh material before rendering
    get isPointsMaterial(): boolean {

        // if points are attenuated - size is in world units, pixels otherwise
        if (!this.sizeAttenuation && this.offset) {
            const height = this.uniforms.viewSize.value.y;
            (this.uniforms.offset.value as Vector2)
                .copy(this.offset)
                .multiplyScalar(window.devicePixelRatio / height);

        } else if (this.offset) {
            if (this._parameters.map.image)
                (this.uniforms.offset.value as Vector2).copy(this.offset);
        }

        this.updateTransparency();
        return false;
    }

    offset: Vector2;
    sizeAttenuation: boolean;

    get size(): number {
        return this.uniforms.size.value;
    }

    private _parameters: Web3DPointsMaterialParameters;

    constructor(parameters: Web3DPointsMaterialParameters, globalUniforms: GlobalMaterialUniforms) {
        const uniforms = Object.assign(UniformsUtils.clone(ShaderLib.points.uniforms), {
            diffuse: { value: new Color(parameters.color) },
            map: { type: 't', value: parameters.map },
            offset: { value: new Vector2() },
            size: { value: parameters.size },
            opacity: { value: parameters.opacity ?? 1},
            transparent: {value: parameters.transparent ?? false},
        }, globalUniforms);

        const params = Object.assign({
            uniforms: uniforms,
            defines: {
                USE_MAP: true,  // TODO: Is this necessary for quad point shaders?
                USE_POINTUV: parameters.usePointUv === undefined ? true : parameters.usePointUv,
                USE_SIZEBUFFER: !!parameters.useSizeBuffer,
                RED_FORMAT: !!(parameters.map && parameters.map.format === RedFormat),
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader
        }, parameters);
        delete params.color;
        delete params.map;
        delete params.size;
        delete params.sizeAttenuation;
        delete params.usePointUv;
        delete params.useSizeBuffer;
        delete params.offset;
        delete params.flipY;

        super(params, globalUniforms);

        this._parameters = parameters;
        this.offset = parameters.offset;
        this.sizeAttenuation = parameters.sizeAttenuation;
        this.alphaTest = 0.001;
        this.side = DoubleSide; // Required to support billboards defined in reverse, which were not culled by previous implementation
    }

    override clone(): this {
        const m = new Web3DMeshPointsMaterial(this._parameters, this._globalUniforms);
        m.sizeAttenuation = this.sizeAttenuation;
        return m as this;
    }
}
